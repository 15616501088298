<template>
  <div>
    <CommonListLabel
      :label="'Seneste ugebrev'"
      :link="'/ugebreve'"
      :translate-label="false"
    />
    <CommonDivider
      :dark="isMm"
      :class="placement === 'side' ? '' : 'mt-0 md:mt-3 mb-3 md:mb-5'"
    />
    <div v-if="magazine" class="mb-3">
      <CommonImageLoader
        :width="516"
        :height="697"
        class="overflow-hidden rounded-md"
        :src="magazine.ImageUrl"
      />
      <NuxtLink :to="link" :external="downloadLinkActive ? true : false">
        <CommonButton
          bg="bg-black"
          text="text-white"
          border="border-none"
          class="mt-6 group"
        >
          {{ downloadLinkActive ? 'Download ugebrev' : 'Login' }}
          <DownloadIcon
            class="text-white fill-current w-4 h-4 ml-1 inline transition-colors duration-100 group-hover:text-black"
          />
        </CommonButton>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import DownloadIcon from '~/assets/icons/icon-action-download.svg?component'
const { isMm } = useBrand()

withDefaults(
  defineProps<{
    placement?: string
  }>(),
  {
    placement: 'side',
  }
)

const nuxtApp = useNuxtApp()
const userStore = useUserStore()

const { data: magazine } = await useAsyncData(
  async () => (await nuxtApp.$api.content.magazine(1))[0]
)

const downloadLinkActive = computed(() => {
  return userStore.isLoggedIn
})

const link = computed(() => {
  if (!magazine.value) {
    return
  }

  if (downloadLinkActive.value) {
    return `${magazine.value.Link}?token=${encodeURI(
      userStore.user?.legacyAutoToken ?? ''
    )}`
  } else {
    return { name: 'login' }
  }
})
</script>
